import React, { FunctionComponent, useCallback } from 'react';
import { isNumeric } from 'validator';
import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';
import { Button, ButtonType } from '@/components/form-controls/button';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { EventFilters, ViewMode } from '@/events/reducer/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { eventsActions } from '@/events/reducer';
import { SearchBox } from '@/events/components/events-filtering/components/search-box';
import { LOCAL_EVENT_FILTER_ZIP_CODE } from '@/constants/local-storage';

export const ZipcodeChangeModal: FunctionComponent = () => {
    const { width } = useWindowDimensions();

    const dispatch = useDispatch();

    const viewMode: ViewMode = useSelector<RootState, ViewMode>((state) => state.events.viewMode);

    const filters: EventFilters = useSelector<RootState, EventFilters>(
        (state) => state.events.filters
    );

    const onClick = useCallback(() => {
        if (width < 768 && viewMode === ViewMode.MAP) {
            dispatch(eventsActions.displayFiltersModal(true));
            return;
        }

        const zipCodeElement = document.getElementById('zipcode-search');

        if (zipCodeElement) {
            zipCodeElement.focus();
        }
    }, [width, viewMode]);

    const onChangeZipCode = useCallback((newZipCode: string) => {
        if (newZipCode.length === 5 && isNumeric(newZipCode)) {
            dispatch(eventsActions.updateZipcodeFilter(newZipCode));
            localStorage.setItem(LOCAL_EVENT_FILTER_ZIP_CODE, newZipCode);
        }
    }, []);

    return (
        <div className={`${Styles.LocationNotProvidedOverlay}`}>
            <div className={`${Styles.OverlayModal}`}>
                <img alt="info-circle-blue-icon" src={IMAGES.InfoCircleBlueIcon} />
                <span className={Styles.Label}>
                    Please enter your zip code for
                    <br /> in-person events near you.
                </span>

                {width < 768 ? (
                    <div className={'pt-3'}>
                        <SearchBox
                            label={''}
                            onChange={onChangeZipCode}
                            value={filters.zipcode}
                            type={'text'}
                            isValid={true}
                            placeholder={'Zip Code'}
                            hideTooltipCompletely={true}
                            iconColor={'#3370AC'}
                        />
                    </div>
                ) : (
                    <Button
                        customClassName="px-4 py-2 mt-3"
                        type={ButtonType.PRIMARY_BLUE_OUTLINE}
                        onClick={onClick}
                    >
                        Add zip code
                    </Button>
                )}
            </div>
        </div>
    );
};
